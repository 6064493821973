import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchNotes from "../components/searchNotes"


class Notes extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Best of" />
        <h1 style={{ fontWeight: 800, marginTop: 20 }} >Best of </h1>
        <p> I write about topics that I'm interested in like product design, brand strategy, marketing, philosophy, creative entrepreneurship, mental models, & more.
        You can subscribe to my <a href={`https://atulprd.substack.com/subscribe`} target="_blank" rel="noopener noreferrer" > newsletter</a> to receive these notes in your inbox. </p>
        <h2>Big Themes</h2>
        <li><b><a href={`https://www.atulprd.com/blog/?search=ux+research`}>UX Research</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=product+design`}>Product Design</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=personal+growth`}>Personal Growth</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=book+notes`}>Book Notes</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=creativity`}>Creativity</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=productivity`}>Productivity</a></b></li>
        <li><b><a href={`https://www.atulprd.com/blog/?search=productivity`}>Personal Finance</a></b></li>
        <hr/>
        <h3>Like what you are reading?</h3>
        <p>If you're interested in these topics, you should sign up for my newsletter, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on business, life & tech. </p>
        <Link to={`https://atulprd.substack.com/subscribe`} target="_blank" rel="noopener noreferrer">
          <Button marginTop="35px">Join the newsletter</Button>
        </Link>
      </Layout>
    )
  }
}

export default Notes

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
